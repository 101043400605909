import React from 'react'
import Footer from '../sections/Footer'
import AboutHeader from '../sections/AboutHeader'

const About = () => (
    <React.Fragment>
        <AboutHeader />

        <section className="article">
            <div className="foreground">
                <h2>Cine suntem</h2>
                <p>Bine ai venit! Te invităm alături de noi în cadrul întâlnirilor noastre. Vrem să te cunoaștem mai bine, să ne închinăm împreună, să studiem Cuvântul lui Dumnezeu, și să ne lăsăm transformați de El în gândirea și trăirea noastră.</p>
                <p>Fie că ai ajuns aici din întâmplare sau pentru că dorești să afli mai multe informații despre noi, dorim ca timpul pe care-l petreci explorând site-ul nostru să fie unul cât se poate de plăcut și benefic. De aceea ți-am pus la dispoziție câteva informații despre noi și despre ceea ce credem, resurse audio și video din cadrul întâlnirilor de închinare ale bisericii noastre, pentru ca acolo unde ești să poți gusta o mică parte din experiențele comunității noastre. De asemenea, ne rugăm ca toate aceste resurse să te ajute în a-L cunoaște mai bine pe Hristos și în creșterea ta spirituală.</p>
                <p>Biserica Creștină Baptistă Cetatea este o comunitate de credincioși care doresc să dobândească o gândire biblică, care să fundamenteze o trăire creștină autentică, pentru a avea o mărturie relevantă în societate.</p>
                <p>Dumnezeu să te binecuvinteze!</p>

                <h3>Comitetul bisericii</h3>
                <hr />    
                <p><strong>Pastor</strong> Păun Belciu - predicare, pastorală, coordonarea lucrării cu tinerii</p>

                <p><strong>Presbiter</strong> Claudiu Almășan – secretar, cateheză, rugăciune</p>
                    
                <p><strong>Presbiter</strong> Mircea Muntian – cateheză, coordonarea lucrării de misiune locală, studiu biblic, asistență pastorală pentru bolnavi</p>

                <p><strong>Presbiter</strong> Emanuel Muntean – coordonarea grupelor de studiu și ucenicie, clubul de predicare, misiune locală, asistență socială</p>

                <p><strong>Diacon</strong> Mircea Dragomir jr. - casier</p>

                <p><strong>Diacon</strong> Cornel Moldovan – primire de oaspeți, coordonarea proiectului social: "Prânzul pentru Iisus", asistență socială</p>

                <p><strong>Diacon</strong> Daniel Novăcean - administrație</p>

                <p><strong>Diacon</strong> Eugen M. Ștef - administrație</p>

                <p><strong>Diacon</strong> Gabi Gruian - coordonarea lucrării cu copiii, tineret, lucrarea muzicală</p>

                <p><strong>Diacon</strong> Beni Dragomir - administrație, media, lucrarea muzicală</p>
                    
                <p><strong>Diacon</strong> Iosif Gruian – media</p>
            </div>
        </section>

        <Footer />
    </React.Fragment>
)

export default About
