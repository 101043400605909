import React, { useState, useEffect } from 'react'

import HomeHeaderLive from '../containers/HomeHeaderLive'
import HomeHeaderOffline from '../containers/HomeHeaderOffline'
import WelcomeSection from '../sections/WelcomeSection'
import MediaSection from '../containers/MediaSection'
import ContactSection from '../sections/ContactSection'
import Footer from '../sections/Footer'

const Home = () => {
    const [isLive, setIsLive] = useState(false)
    const [channelId, setChannelId] = useState('')

    useEffect(() => {
        fetch('/json/live.json')
            .then(response => response.json())
            .then(({ channelId, isLive }) => {
                setIsLive(isLive)
                setChannelId(channelId)
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <React.Fragment>
            {isLive && channelId ? <HomeHeaderLive channelId={channelId} /> : <HomeHeaderOffline />}
            <WelcomeSection />
            <MediaSection />
            <ContactSection />
            <Footer />
        </React.Fragment>
    )
}

export default Home