import { connect } from 'react-redux'
import { hideModal } from '../actions'
import ModalSection from '../sections/ModalSection'

const mapStateToProps = state => {
    return {
        isVisible: state.modal.isModalVisible,
        details: state.modal.details
    }
}

const mapDispatchToProps = {
    hideModal
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalSection)