import React from 'react'

const AudioPlayer = props => {
    return (
        <div className="audioPlayer">
            <audio controls src={props.url} controlsList="nodownload"/>
        </div>
    )
}

export default AudioPlayer