import React from 'react'

const Section = props => (
    <section className={props.className}>{props.children}</section>
)

const SectionTitle = (props) => (
    <h2 className="white">{props.children}</h2>
)

const SectionBackground = () => (
    <div className="background"></div>
)

export {
    SectionBackground, SectionTitle, Section
}