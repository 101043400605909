import React from 'react'
import AudioThumbnail from '../components/AudioThumbnail'
import YoutubeThumbnail from '../components/YoutubeThumbnail'

class MediaGrid extends React.Component {
    onItemClick(item) {
        this.props.onItemClick(item)
    }

    render() {
        return (
            <div className="grid">
                {this.props.items.map((mediaItem, mediaIndex) => {
                    if (mediaItem.type === 'youtube-video') {
                        return <YoutubeThumbnail key={mediaIndex} {...mediaItem} onClick={this.onItemClick.bind(this, mediaItem)} />
                    }

                    return <AudioThumbnail key={mediaIndex} {...mediaItem} onClick={this.onItemClick.bind(this, mediaItem)} />
                })}
            </div>
        )
    }
}
export default MediaGrid