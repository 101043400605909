import React from 'react'
import Logo from './Logo'
import Menu from './Menu'

const GenericHeader = props => {
    const { className, middleContent, bottomContent } = props

    const foregroundBottom = (
        <div className="foreground-bottom">
            <div className="content">
                {bottomContent}
            </div>
        </div>
    )

    return (
        <header className={className}>
            <div className="background"></div>
            <div className="foreground">
                <div className="foreground-top">
                    <div className="content">
                        <Logo />
                        <Menu />
                    </div>
                </div>
                <div className="foreground-middle">
                    <div className="content">
                        {middleContent}
                    </div>
                </div>
                {!bottomContent ? <div /> : foregroundBottom}
            </div>
        </header>
    )
}

export default GenericHeader