import React from 'react'
import Modal from '../components/Modal'

const ModalSection = props => {
    if (props.isVisible) {
        return <Modal {...props.details} onClose={props.hideModal} />
    }
    return null
}

export default ModalSection