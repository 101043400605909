import React from 'react'
import YoutubeIframe from './YoutubeIframe'
import AudioPlayer from './AudioPlayer'

class Modal extends React.Component {
    renderMediaItem() {
        switch (this.props.type) {
            case 'youtube-video':
                return <YoutubeIframe id={this.props.videoId} />
            default:
                return <AudioPlayer url={this.props.url}/>
        }
    }

    render() {
        const { title, subtitle, type, onClose } = this.props
        const className = type === 'audio' ? 'foreground smallModal' : 'foreground';

        return (
            <div className="modal">
                <div className="background"></div>
                <div className={className}>
                    <div className="foreground-top">
                        {!!title && !!subtitle ? <h3>{title} - {subtitle}</h3> : <div/>}
                        <div className="closeButton" onClick={onClose}>
                            <i className="fa fa-times"></i>
                        </div>
                    </div>
                    <div className="foreground-middle">
                        {this.renderMediaItem()}
                    </div>
                    <div className="foreground-bottom"></div>
                </div>
            </div>
        )
    }
}

export default Modal