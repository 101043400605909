import React from 'react'
import Button from '../components/Button'
import MediaGrid from '../components/MediaGrid'

class MediaSection extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            mediaItems: []
        }
    }

    extractLatestMediaItems(archive) {
        const result = []
        archive.forEach(month => {
            result.push(...month.media)
        })
        return result.slice(0, 6)
    }

    componentDidMount() {
        fetch('/json/archive.json')
            .then(archive => archive.json())
            .then(archive => {
                this.setState({
                    mediaItems: this.extractLatestMediaItems(archive)
                })
            })
    }

    render() {
        return (
            <section className="media">
                <div className="foreground">
                    {this.renderTextTags()}

                    <MediaGrid items={this.state.mediaItems} onItemClick={this.props.showModal} />

                    <div className="buttonsWrapper">
                        <Button to="/media">Arhivă media</Button>
                    </div>
                </div>
            </section>
        )
    }

    renderTextTags() {
        return (
            <React.Fragment>
                <h2>Media</h2>
                <p>Fie că ai ajuns aici din întâmplare sau pentru că dorești să afli mai multe informații despre noi, dorim ca timpul pe
                care-l petreci explorând site-ul nostru să fie unul cât se poate de plăcut și benefic. De aceea ți-am pus la dispoziție
                câteva informații despre noi și despre ceea ce credem, resurse audio și video din cadrul întâlnirilor de închinare ale
            bisericii noastre, pentru ca acolo unde ești să poți gusta o mică parte din experiențele comunității noastre.</p>
                <p>Ne rugăm ca toate aceste resurse să te ajute în a-L cunoaște mai bine pe Hristos și în creșterea ta spirituală.</p>
            </React.Fragment>
        )
    }
}

export default MediaSection