import React from 'react'

const YoutubeThumbnail = props => {
    const { videoId, onClick } = props
    const thumbnail = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    
    const style = {
        backgroundImage: `url(${thumbnail})`
    }

    return (
        <div className="video" onClick={onClick}>
            <div className="background" style={style} />
            <div className="overlay"></div>
        </div>
    )

}

export default YoutubeThumbnail