import React from 'react'
import GenericHeader from '../components/GenericHeader'

export default ({ channelId }) => {
    const middleContent = (
        <>
            {channelId && <iframe width="1280" height="720" title="Biserica Cetatea" src={`https://www.youtube.com/embed/live_stream?channel=${channelId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>}
        </>
    )
    const bottomContent = null

    const headerClassName = 'home'
    const headerProps = { className: headerClassName, middleContent, bottomContent }

    return <GenericHeader {...headerProps} />
}
