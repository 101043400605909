import React from 'react'

const AudioThumbnail = props => {
    const { title, subtitle, onClick } = props
    const thumbnail = `/images/media-audio-thumbnail.webp`

    const style = {
        backgroundImage: `url(${thumbnail})`
    }

    return (
        <div className="video audio" onClick={onClick}>
            <div className="background" style={style} />
            <div className="overlay">
                <h3>{title}</h3>
                <p>{subtitle}</p>
            </div>
        </div>
    )

}

export default AudioThumbnail