import React from 'react'
import { Link } from 'react-router-dom'
import Button from './Button'

class Menu extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            isSmallMenuVisible: false
        }
        this.toggleMenu = this.toggleMenu.bind(this)

    }

    toggleMenu() {
        this.setState(state => ({
            isSmallMenuVisible: !state.isSmallMenuVisible
        }))
    }

    render() {
        return (
            <React.Fragment>
                {this.renderLargeMenu()}
                {this.renderSmallMenu()}
            </React.Fragment>
        )
    }

    renderLargeMenu() {
        return (
            <nav className="largeScreen">
                <Link to="/">Acasă</Link>
                <Link to="/despre-noi">Despre noi</Link>
                <Link to="/media">Media</Link>
            </nav>
        )
    }

    renderSmallMenu() {
        return (
            <nav className="smallScreen">
                <Button className="toggle" onClick={this.toggleMenu}>
                    <i className="fa fa-bars" aria-hidden="true"></i>
                </Button>
                {this.state.isSmallMenuVisible ? <div className="links">
                    <div className="toggleWrapper">
                        <Button onClick={this.toggleMenu}>
                            <i className="fa fa-times" aria-hidden="true"></i>
                        </Button>
                    </div>
                    <Link to="/">Acasă</Link>
                    <Link to="/despre-noi">Despre noi</Link>
                    <Link to="/media">Media</Link>
                </div> : null}
            </nav>
        )
    }
}

export default Menu