import { SHOW_MODAL, HIDE_MODAL } from '../constants'

export function showModal(details) {
    return dispatch => {
        dispatch({
            type: SHOW_MODAL,
            details
        })
    }
}

export function hideModal() {
    return dispatch => {
        dispatch({
            type: HIDE_MODAL
        })
    }
}