import React from 'react'
import { Link } from 'react-router-dom'

const Button = props => {
    const { size, transparent, to, children, onClick, className } = props
    const buttonClassName = `${size === 'large' ? 'btn-large' : 'btn-small'} ${transparent ? 'transparent' : ''} ${to ? 'buttonLink' : ''} ${className ? className : ''}`

    if (to) {
        return <Link className={buttonClassName} to={to}>{children}</Link>
    }

    return <button className={buttonClassName} onClick={onClick}>{children}</button>
}

export default Button
