import { SHOW_MODAL, HIDE_MODAL } from '../constants'

const initialState = {
    isModalVisible: false,
    details: {}
}

const resource = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                isModalVisible: true,
                details: action.details
            };
        case HIDE_MODAL:
            return initialState
        default:
            return state
    }
}

export default resource