import React from 'react'
import GenericHeader from '../components/GenericHeader'

export default () => {
    const middleContent = (
        <>
            <h2>Bun venit pe site-ul bisericii noastre!</h2>
        </>
    )
    const bottomContent = null

    const headerClassName = 'home home-offline'
    const headerProps = { className: headerClassName, middleContent, bottomContent }

    return <GenericHeader {...headerProps} />
}
