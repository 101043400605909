import React from 'react'
import Button from '../components/Button'

const WelcomeSection = () => (
    <section className="welcome">
        <div className="foreground">
            <h2>Cine suntem</h2>
            <div className="row">
                <div className="left">
                    <p>Bine ai venit! Te invităm alături de noi în cadrul întâlnirilor noastre. Vrem să te cunoaștem mai bine, să ne închinăm împreună, să studiem Cuvântul lui Dumnezeu, și să ne lăsăm transformați de El în gândirea și trăirea noastră.</p>
                    <Button to="/despre-noi">Detalii</Button>
                </div>
                <div className="right">
                    <img src="/images/welcome-image.webp" alt="" />
                </div>
            </div>
        </div>
    </section>
)

export default WelcomeSection