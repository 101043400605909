import React from 'react'

const Footer = () => (
    <footer>
        <div className="foreground">
            <div>Copyright 2020 Cetatea. All rights reserved.</div>
            <div>Powered by <a target="_blank" rel="noopener noreferrer" href="https://fiveem.com">Fiveem</a></div>
        </div>
    </footer>
)

export default Footer