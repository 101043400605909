import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import ScrollToTop from './components/ScrollToTop'
import Home from './screens/Home'
import About from './screens/About'
import ModalSection from './containers/ModalSection'

// import Blog from './screens/Blog'
// import BlogPost from './screens/BlogPost'
// import PresentsScreen from './screens/PresentsScreen'

class App extends React.Component {
    render() {
        return (
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/despre-noi" component={About} />

                        <Route exact path="/media" component={() => {
                            window.location.href = 'https://www.youtube.com/c/BisericaCetateaAlbaIulia';
                            return null;
                        }} />

                        {/* <Route exact path="/blog" component={Blog} />
                        <Route exact path="/blog/:id" component={BlogPost} /> */}
                        {/* <Route exact path="/cadou" component={PresentsScreen} /> */}

                        <Route component={() => <Redirect to="/" />} />
                    </Switch>
                    <ModalSection />
                </ScrollToTop>
            </Router>
        )
    }
}

export default App;
